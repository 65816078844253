<template>
  <div class="col-12">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div class="form-group">
                    <label for="">اختر التاريخ</label>
                    <input type="date"
                        class="form-control" v-model="date" id="ddate" @change="getReport()">
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="form-group">
                        <label>اختر حالة معينة</label>
                        <select class="form-control" v-model="status" @change="getReport()">
                            <option :value="'all'">الكل</option>
                            <option value="1">الحضور</option>
                            <option value="3">غائب</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="form-group">
                        <label>اختر لجنة معينة</label>
                        <select class="form-control" v-model="selected_comm" @change="getReport()">
                            <option :value="'all'">الكل</option>
                            <template v-for="comm in comms">
                                <option :value="comm" :key="comm">{{comm}}</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="form-group">
                        <label>الفترة</label>
                        <select class="form-control" v-model="fatra" @change="getReport()">
                            <option value="1">الفترة الأولى</option>
                            <option value="2">الفترة الثانية</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <div>
                <input type="text" placeholder="ابحث هنا..." @change="getReport()" id="qq" v-model="q" style="border: 1px solid #999; border-radius: 3px; padding: 7px; width: 200px;max-width: 80%;">
                <img :src="require('@/assets/images/loading.svg')" style="width: 40px;" v-if="show_search_loading || loading">
                &nbsp;
                <button class="btn btn-warning btn-sm" @click="print()">
                    <i class="fa fa-print"></i>
                    طباعة
                </button>
                &nbsp;
                <button class="btn btn-success btn-sm" @click="exportExcel()">
                    <i class="fa fa-file"></i>
                    تصدير الى Excel اكسل
                </button>
            </div>
        </div>
        <template v-if="!loading">
            <div class="card-body">
                <div class="col-12 table-responsive" id="table">
                    <table class="table table-sm table-bordered table-hover">
                        <thead>
                            <th>
                                م
                            </th>
                            <th>
                                الطالب
                            </th>
                            <th>
                                الحالة
                            </th>
                            <th>
                                الفصل
                            </th>
                            <th>
                                اللجنة
                            </th>
                            <th>
                                التوقيت
                            </th>
                            <th>
                                المعلم
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(report, i) in reports" :key="report._id">
                                <td>
                                    {{ i+1 }}
                                </td>
                                <td>
                                    {{ report.name }}
                                </td>
                                <td>
                                    <span v-if="!report.report.notexists">
                                        <span v-if="report.report.status == '1'" class="btn btn-sm btn-success btn-block" style="border-radius: 2px; padding: 5px">
                                            حاضر
                                        </span>
                                        <span v-if="report.report.status == '3'" class="btn btn-sm btn-danger btn-block" style="border-radius: 2px; padding: 5px">
                                            غائب
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    {{ report.classname }} - {{ report.classroom }}
                                </td>
                                <td>
                                    {{ report.title }}
                                </td>
                                <td>
                                    <span v-if="!report.report.notexists">
                                        {{ report.report.time }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="!report.report.notexists">
                                        {{ report.report.teacher_name }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </div>
  </div>
</template>

<script>
var XLSX = require("xlsx");
export default {
    data(){
        return {
            date: new Date().toISOString().split("T")[0],
            reports: [],
            user: JSON.parse(localStorage.getItem('user')),
            loading: true,
            comms: [],
            inter: null,
            status: "all",
            selected_comm: "all",
            id: window.location.hash.replace("#", ""),
            show_search_loading: false,
            q: "",
            fatra: "1",
            last_q: "",
            inter2: null
        }
    },
    created(){
        if(!checkPer("committees")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.inter = setInterval(() => {

            if($("#qq").val() != last_q && $("#qq").val() && $("#qq").val() != "" || ($("#qq").val() == "" && last_q != "" && last_q != null)){
                g.show_search_loading = true;
            }
            if($("#ddate").val() != g.date){
                g.date = $("#ddate").val()
                g.getReport()
            }
        }, 100);
        var last_q = null;
        g.inter2 = setInterval(() => {
            g.show_search_loading = false;
            if($("#qq").val() != last_q && $("#qq").val() && $("#qq").val() != "" || ($("#qq").val() == "" && last_q != "" && last_q != null)){
                last_q = $("#qq").val();
                g.getReport()
            }
        }, 2000);
        g.getReport()
    },
    beforeDestroy(){
        if(this.inter){
            clearInterval(this.inter)
        }
        if(this.inter2){
            clearInterval(this.inter2)
        }
    },
    methods: {
        exportExcel(){
            const wb = XLSX.utils.book_new(), g = this;
            const Heading = [
                [
                    "م",
                    "الاسم",
                    "الفصل",
                    "الحالة",
                    "التوقيت",
                    "المعلم"
                ]
            ];
            var xx = [];
            g.reports.forEach(function(a, i){
                xx.push([
                    i+1,
                    a.name,
                    a.classname + ' - ' + a.classroom,
                    a.report.status?.toString().replace("0", "خارج وقت الدوام")
                    .replace("1", "حاضر")
                    .replace("3", "غائب")
                    .replace("5", "غائب بعذر"),
                    a?.report?.time,
                    a?.report?.teacher_name,
                ])
            })
            
            // creating sheet and adding data from 2nd row of column A.
            // leaving first row to add Heading
            const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                
            // adding heading to the first row of the created sheet.
            // sheet already have contents from above statement.
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                
            // appending sheet with a name
            XLSX.utils.book_append_sheet(wb, ws, 'Records');
                
            const fileContent = XLSX.writeFile(wb, $("title").first().text().split("—") + ".xlsx");
        },
        print(){
            var win = window.open();
            win.document.write(`
            <html dir='rtl'>
                <head>
                    <style>
                    table, td, th {
                        border: 1px solid;
                    }

                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    </style>
                </head>
                <body>
                    ${$("#table").html()}
                </body>
            </html>
            `)
            win.document.close()
            setTimeout(function(){
                win.print()
            }, 1000)
        },
        getReport(){
            var g = this;
            g.reports = [];
            g.loading = true;
            $.post(api + '/user/committees/reports/daily', {
                jwt: this.user.jwt,
                date: g.date,
                status: g.status,
                comm: g.selected_comm,
                q: this.q,
                id: g.id,
                fatra: g.fatra
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.reports = r.response.reports
                    g.comms = r.response.comms
                }
            }).fail(function(){
                alert('حدث خطأ في الاتصال', 200);
                g.loading = false;
            })
        },
    }
}
</script>

<style>

#table *{
    white-space: nowrap;
}
</style>